import React from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import "./BookingPage.css";

// import Typed from "react-typed";
// import { Link } from "react-scroll";
import { SiTiktok } from "react-icons/si";
import { useState } from "react";
import Pagetitle from "../components/elements/Pagetitle";
var classNames = require("classnames");

const BOOKING_PRICE = 200; // Giá book 1 giờ
const USDRATE = 26000; // Tỷ giá USD

function HomepageLight() {
  React.useEffect(() => {
    document.title = "Lê Hồng Thái | Booking";
  });
  document.body.classList.remove("dark");

  document.addEventListener("click", function (e) {});

  return (
    <>
      <Element name="section-home">
        <ReactCursorPosition>
          <Herosection dark />
        </ReactCursorPosition>
      </Element>
    </>
  );
}

function Herosection(props) {
  const { x, y } = props.position;
  const { height, width } = props.elementDimensions;
  const activeParallax = (depth = 15) => {
    let posX = (width / 2 - x) / depth;
    let posY = (height / 2 - y) / depth;
    return {
      transform: `translate(${posX}px, ${posY}px)`,
    };
  };

  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
      style={{ height: 1000 }}
    >
      <div className="container">
        <div className="intro">
          <img
            src="/images/thai-avt.jpg"
            style={{ borderRadius: "50%" }}
            alt="Thai"
            className="mb-4"
            width="125px"
          />

          <h1 className="mb-2 mt-0">Lê Hồng Thái</h1>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/LeHongThaiREAL/">
                <i className="fab fa-facebook-square"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/lehongthaireal">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.tiktok.com/@lehongthaireal">
                <SiTiktok height={5} width={15} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://m.me/lehongthaireal">
                <i className="fab fa-facebook-messenger"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://t.me/taiphiet">
                <i className="fab fa-telegram-plane"></i>
              </a>
            </li>
          </ul>

          <Booking />
        </div>

        <div className="parallax" data-relative-input="true">
          <svg
            width="27"
            height="29"
            className="layer p1"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(15)}
          >
            <path
              d="M21.15625.60099c4.37954 3.67487 6.46544 9.40612 5.47254 15.03526-.9929 5.62915-4.91339 10.30141-10.2846 12.25672-5.37122 1.9553-11.3776.89631-15.75715-2.77856l2.05692-2.45134c3.50315 2.93948 8.3087 3.78663 12.60572 2.22284 4.297-1.5638 7.43381-5.30209 8.22768-9.80537.79387-4.50328-.8749-9.08872-4.37803-12.02821L21.15625.60099z"
              fill="#FFD15C"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="26"
            height="26"
            className="layer p2"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(20)}
          >
            <path
              d="M13 3.3541L2.42705 24.5h21.1459L13 3.3541z"
              stroke="#FF4C60"
              strokeWidth="3"
              fill="none"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="30"
            height="25"
            className="layer p3"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(25)}
          >
            <path
              d="M.1436 8.9282C3.00213 3.97706 8.2841.92763 14.00013.92796c5.71605.00032 10.9981 3.04992 13.85641 8 2.8583 4.95007 2.8584 11.0491-.00014 16.00024l-2.77128-1.6c2.28651-3.96036 2.28631-8.84002.00011-12.8002-2.2862-3.96017-6.5124-6.40017-11.08513-6.4-4.57271.00018-8.79872 2.43984-11.08524 6.4002l-2.77128-1.6z"
              fill="#44D7B6"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="15"
            height="23"
            className="layer p4"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(10)}
          >
            <rect
              transform="rotate(30 9.86603 10.13397)"
              x="7"
              width="3"
              height="25"
              rx="1.5"
              fill="#FFD15C"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="15"
            height="23"
            className="layer p5"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(8)}
          >
            <rect
              transform="rotate(30 9.86603 10.13397)"
              x="7"
              width="3"
              height="25"
              rx="1.5"
              fill="#6C6CE5"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="49"
            height="17"
            className="layer p6"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(18)}
          >
            <g fill="#FF4C60" fillRule="evenodd">
              <path d="M.5 16.5c0-5.71709 2.3825-10.99895 6.25-13.8567 3.8675-2.85774 8.6325-2.85774 12.5 0C23.1175 5.50106 25.5 10.78292 25.5 16.5H23c0-4.57303-1.90625-8.79884-5-11.08535-3.09375-2.28652-6.90625-2.28652-10 0C4.90625 7.70116 3 11.92697 3 16.5H.5z" />
              <path d="M23.5 16.5c0-5.71709 2.3825-10.99895 6.25-13.8567 3.8675-2.85774 8.6325-2.85774 12.5 0C46.1175 5.50106 48.5 10.78292 48.5 16.5H46c0-4.57303-1.90625-8.79884-5-11.08535-3.09375-2.28652-6.90625-2.28652-10 0-3.09375 2.28651-5 6.51232-5 11.08535h-2.5z" />
            </g>
          </svg>

          <svg
            width="26"
            height="26"
            className="layer p7"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(22)}
          >
            <path
              d="M13 22.6459L2.42705 1.5h21.1459L13 22.6459z"
              stroke="#FFD15C"
              strokeWidth="3"
              fill="none"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="19"
            height="21"
            className="layer p8"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(15)}
          >
            <rect
              transform="rotate(-40 6.25252 10.12626)"
              x="7"
              width="3"
              height="25"
              rx="1.5"
              fill="#6C6CE5"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="30"
            height="25"
            className="layer p9"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(10)}
          >
            <path
              d="M29.8564 16.0718c-2.85854 4.95114-8.1405 8.00057-13.85654 8.00024-5.71605-.00032-10.9981-3.04992-13.85641-8-2.8583-4.95007-2.8584-11.0491.00014-16.00024l2.77128 1.6c-2.28651 3.96036-2.28631 8.84002-.00011 12.8002 2.2862 3.96017 6.5124 6.40017 11.08513 6.4 4.57271-.00018 8.79872-2.43984 11.08524-6.4002l2.77128 1.6z"
              fill="#6C6CE5"
              fillRule="evenodd"
            />
          </svg>

          <svg
            width="47"
            height="29"
            className="layer p10"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(23)}
          >
            <g fill="#44D7B6" fillRule="evenodd">
              <path d="M46.78878 17.19094c-1.95535 5.3723-6.00068 9.52077-10.61234 10.8834-4.61167 1.36265-9.0893-.26708-11.74616-4.27524-2.65686-4.00817-3.08917-9.78636-1.13381-15.15866l2.34923.85505c-1.56407 4.29724-1.2181 8.92018.90705 12.12693 2.12514 3.20674 5.70772 4.5107 9.39692 3.4202 3.68921-1.0905 6.92581-4.40949 8.48988-8.70673l2.34923.85505z" />
              <path d="M25.17585 9.32448c-1.95535 5.3723-6.00068 9.52077-10.61234 10.8834-4.61167 1.36264-9.0893-.26708-11.74616-4.27525C.16049 11.92447-.27182 6.14628 1.68354.77398l2.34923.85505c-1.56407 4.29724-1.2181 8.92018.90705 12.12692 2.12514 3.20675 5.70772 4.5107 9.39692 3.4202 3.68921-1.0905 6.92581-4.40948 8.48988-8.70672l2.34923.85505z" />
            </g>
          </svg>

          <svg
            width="33"
            height="20"
            className="layer p11"
            xmlns="http://www.w3.org/2000/svg"
            style={activeParallax(18)}
          >
            <path
              d="M32.36774.34317c.99276 5.63023-1.09332 11.3614-5.47227 15.03536-4.37895 3.67396-10.3855 4.73307-15.75693 2.77837C5.76711 16.2022 1.84665 11.53014.8539 5.8999l3.15139-.55567c.7941 4.50356 3.93083 8.24147 8.22772 9.8056 4.29688 1.56413 9.10275.71673 12.60554-2.2227C28.34133 9.98771 30.01045 5.4024 29.21635.89884l3.15139-.55567z"
              fill="#FFD15C"
              fillRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

function Booking(props) {
  const [formdata, setFormdata] = useState({
    name: "",
    phone: "",
    note: "",
    type: "",
    email: "",
  });

  const [step, setStep] = useState(1);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [bookingOption, setBookingOption] = useState("online");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Vui lòng điền tên đầy đủ.");
    } else if (!formdata.phone) {
      setError(true);
      setMessage("Vui lòng điền số điện thoại.");
    } else {
      setError(false);
      setMessage(`Đã đặt lịch`);

      const message = `☕️ Booking Coffee
- Tên: ${formdata.name} 
- Sđt: ${formdata.phone} 
- Email: ${formdata.email}
- Ghi chú: ${formdata.note} 
- Trò chuyện: ${bookingOption}`;

      fetch(
        `https://api.telegram.org/bot782377690:AAEo5rJ6XYrsL9lna2kErvJkAGvirSZEQzs/sendMessage?chat_id=548860872&text=${encodeURIComponent(
          message
        )}`
      )
        .then((response) => response.json())
        .then((data) => console.log("Thông tin đã được gửi thành công"));

      setStep(2);
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const NotifyTransfered = async () => {
    // document.querySelector("#submit").style.display = "none";
    document.querySelector("#submit").disabled = true;
    document.querySelector("#submit").innerHTML = "⏳ Đang kiểm tra...";
    document.querySelector("#submit").className = "btn btn-info";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      description: `CAFE${formdata.phone}`,
      amount: BOOKING_PRICE * USDRATE + "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://api-quachtinh.lehongthai.vip/banks/acb-thai-check",
        requestOptions
      ).then((response) => response.json());
      console.log(res);
      if (res.success) {
        if (res.data?.amount) {
          // ĐÃ NHẬN TIỀN
          const message = `
          - 💰 Đã chuyển khoản Booking Coffee
- Tên: ${formdata.name}
- Sđt: ${formdata.phone}
- Email: ${formdata.email}
- Ghi chú: ${formdata.note}
- Trò chuyện: ${bookingOption}
          `;

          fetch(
            `https://api.telegram.org/bot782377690:AAEo5rJ6XYrsL9lna2kErvJkAGvirSZEQzs/sendMessage?chat_id=548860872&text=${encodeURIComponent(
              message
            )}`
          )
            .then((response) => response.json())
            .then((data) => console.log("Thông tin đã được gửi thành công"));

          setStep(4);
        } else {
          // CHƯA NHẬN TIỀN
          setStep(5);
        }
      } else {
        // Lỗi API tpbank
        const message = `
        - 💰❌ (API Lỗi, hãy check thủ công) Đã chuyển khoản Booking Coffee
        - Tên: ${formdata.name}
        - Sđt: ${formdata.phone}
        - Email: ${formdata.email}
        - Ghi chú: ${formdata.note}
        - Trò chuyện: ${bookingOption}
        `;

        fetch(
          `https://api.telegram.org/bot782377690:AAEo5rJ6XYrsL9lna2kErvJkAGvirSZEQzs/sendMessage?chat_id=548860872&text=${encodeURIComponent(
            message
          )}`
        )
          .then((response) => response.json())
          .then((data) => console.log("Thông tin đã được gửi thành công"));

        setStep(3);
        return;
      }
    } catch (error) {
      // LỖI SERVER BACKEND
      const message = `Lỗi Backend my-api quachtinh lehongthai,vip`;

      fetch(
        `https://api.telegram.org/bot782377690:AAEo5rJ6XYrsL9lna2kErvJkAGvirSZEQzs/sendMessage?chat_id=548860872&text=${encodeURIComponent(
          message
        )}`
      )
        .then((response) => response.json())
        .then((data) => console.log("Thông tin đã được gửi thành công"));
    }
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4 w-100">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4 w-100">{message}</div>;
    } else {
      return null;
    }
  };
  if (step === 1)
    return (
      <div className="">
        <div
          className="container rounded p-5 mt-3 mb-0"
          style={{ backgroundColor: "#F9F9FE" }}
        >
          <h3 className="section-title">Đặt lịch tư vấn</h3>
          <div className="spacer" data-height="20"></div>

          <div className="fee badge badge-pill badge-warning">
            Mức phí: ${BOOKING_PRICE + " "}
            {BOOKING_PRICE === 200 ? "" : <strike>$̶1̶0̶0̶</strike>}/ giờ
          </div>
          <div className="text-secondary" style={{ fontSize: 11 }}>
            (Có thể biến động theo thời gian)
          </div>
          <div className="spacer" data-height="20"></div>
          <div className="row">
            <form
              id="contact-form"
              className="contact-form"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Tên đầy đủ"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Số điện thoại"
                      onChange={handleChange}
                      value={formdata.phone}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="d-flex text-muted">
                      <div
                        type="text"
                        className={classNames("BookingOption", {
                          Active: bookingOption === "online",
                        })}
                        id="online"
                        onClick={() => setBookingOption("online")}
                      >
                        Gọi online
                      </div>
                      <div
                        type="text"
                        className={classNames("BookingOption", {
                          Active: bookingOption === "offline",
                        })}
                        id="offline"
                        onClick={() => setBookingOption("offline")}
                      >
                        Gặp mặt
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email nhận thông tin đặt lịch"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="note"
                      name="note"
                      placeholder="Ghi chú thêm"
                      onChange={handleChange}
                      value={formdata.note}
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Đặt ngay
              </button>
            </form>

            {handleAlerts()}
          </div>
        </div>
      </div>
    );
  else if (step === 2)
    return (
      <div>
        <div
          className="container rounded p-5 mt-3 mb-0"
          style={{ backgroundColor: "#F9F9FE" }}
        >
          <Pagetitle title="Đặt lịch tư vấn" />
          <div className="">
            <div className="row d-flex  flex-column align-items-center">
              <h3>Thông tin thanh toán</h3>
              <div>
                - Ngân hàng: Vietinbank
                <br />- Số tài khoản:{" "}
                <span
                  className="text-danger"
                  onClick={() => {
                    navigator.clipboard.writeText("100879367055");
                    let stk = document.querySelector(
                      "#home > div > div.intro > div > div > div:nth-child(3) > div:nth-child(1) > div > span:nth-child(2)"
                    );
                    stk.innerHTML = "✅ Đã sao chép.";
                    setTimeout(() => {
                      stk.innerHTML = "100879367055 📋";
                    }, 1000);
                  }}
                >
                  <div>
                    100879367055{" "}
                    <span role="img" aria-label="Copy">
                      📋 (copy)
                    </span>
                  </div>
                </span>{" "}
                <br />
                - Chủ tài khoản: Lê Hồng Thái
                <br />- Nội dung chuyển tiền:{" "}
                <span className="text-danger">CAFE{formdata.phone}</span> <br />
                - Số tiền:{" "}
                <span>
                  {Number((BOOKING_PRICE * USDRATE).toFixed(1)).toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </span>
              </div>
            </div>
            <div className="spacer" data-height="60"></div>
            <div className="row d-flex  flex-column align-items-center">
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
                onClick={() => NotifyTransfered()}
              >
                Xác nhận đã chuyển tiền
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  else if (step === 3)
    return (
      <div>
        <div
          className="container rounded p-5 mt-3 mb-0"
          style={{ backgroundColor: "#F9F9FE" }}
        >
          <Pagetitle title="Đặt lịch tư vấn" />
          <div className="">
            <div className="row d-flex  flex-column align-items-center">
              <h3>Yêu cầu đã được gửi đi</h3>
              <div>
                Bạn sẽ nhận được liên hệ qua Email trong vòng 48 giờ nếu đã
                thanh toán thành công.
              </div>
            </div>
            <div className="spacer" data-height="60"></div>
          </div>
        </div>
      </div>
    );
  else if (step === 4)
    return (
      <div>
        <div
          className="container rounded p-5 mt-3 mb-0"
          style={{ backgroundColor: "#F9F9FE" }}
        >
          <Pagetitle title="Đặt lịch tư vấn" />
          <div className="">
            <div className="row d-flex  flex-column align-items-center">
              <h3>ĐẶT LỊCH THÀNH CÔNG</h3>
              <div>
                Bạn sẽ nhận được liên hệ qua Email trong vòng 48 giờ tới.
              </div>
            </div>
            <div className="spacer" data-height="60"></div>
          </div>
        </div>
      </div>
    );
  else if (step === 5)
    return (
      <div>
        <div
          className="container rounded p-5 mt-3 mb-0"
          style={{ backgroundColor: "#F9F9FE" }}
        >
          <Pagetitle title="Đặt lịch tư vấn" />
          <div className="">
            <div className="row d-flex  flex-column align-items-center">
              <h3>THẤT BẠI</h3>
              <div>Sự quan tâm của bạn rất đáng được trân trọng!</div>
              <div>
                Tuy nhiên, để đặt lịch hẹn gặp tư vấn, vui lòng thực hiện thanh
                toán. Hãy thử đặt lịch lại lần nữa, xin cảm ơn!
              </div>
              <div>
                Nếu đây là sự cố từ phía ngân hàng, vui lòng thông báo đến
                homthucuathai@gmail.com
              </div>
              <div
                className="btn btn-primary"
                onClick={() => {
                  setStep(2);
                }}
              >
                Thử lại
              </div>
            </div>
            <div className="spacer" data-height="60"></div>
          </div>
        </div>
      </div>
    );
}

export default HomepageLight;
